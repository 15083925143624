<template>
    <div>
        <b-row>
            <b-col sm="4">
                <b-form-group :label="$t('student_number')">
                    {{ this.isNotNullValue(data.student_number) }}
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group :label="$t('faculty')">
                    {{ data.faculty_name }}
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group :label="$t('semester')">
                    {{this.isNotNullValue(data.semester ? data.semester.academic_year : "") + "-" + this.isNotNullValue(this.getSemester(data.semester ? data.semester.semester : "")) }}
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group :label="$t('full_name')">
                    {{this.isNotNullValue(data.name+' '+data.surname)}}
                </b-form-group>
            </b-col>
            <b-col sm="4">
                <b-form-group :label="$t('program')">
                    {{ data.program_name }}
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default{
    props:{
        data:{}
    }
}
</script>
